.box{
    margin: 50px 15px 15px;
    /* box-shadow: 10px 10px 20px rgba(244,241,241,1) ; */
    border-radius:6px;
    /* animation-name: text;
    animation-duration: 7s;
    animation-timing-function: ease-out; */
}
.Interest {
    /* background-color:rgba(220, 220,220, 0.3); */
    border-radius:3px;
    margin-left:20px;
    margin-top:45px;
    padding:20px;
    display:grid;
    grid-template-columns:repeat(3,1fr);
    column-gap: 20px; 
}
.head{
    display: inline-block;
    padding:0 12px;
    color: rgb(153, 153, 153);
    margin-bottom: 20px;
    margin-left:30px;
    letter-spacing: 5px;
    font-size: 11px;
}

.heading {  
    padding:0 12px;
    font-size: 18px;
    font-weight: 700;
    margin-left:30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
}
div>h3{
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 20px 0 30px 0;
}
div>p{
    font-size: 15px;
}
.web{
    height: 100%;
    /* height:180px; */
    border:1px  black;
    border-bottom:2px solid #2c98f0;
    text-align: center;
    padding:1.2rem;
    box-shadow:  10px 10px 20px rgba(0, 0, 0, 0.17) ;
}
.app{
    height: 100%;
    /* height:180px; */
    border:1px  black;
    border-bottom:2px solid #f9bf3f ;
    text-align: center;
    padding:1.2rem;
    box-shadow:  10px 10px 20px rgba(0, 0, 0, 0.17) ;
}
.other{
    height: 100%;
    /* height:180px; */
    border:1px  black;
    border-bottom:2px solid #2fa499;
    text-align: center;
    padding:1.2rem;
    box-shadow:  10px 10px 20px rgba(0, 0, 0, 0.17) ;

}

@keyframes text{
    from{transform: translateX(-30px);
        opacity:0;
    }
  }

/*///////// Media Query //////////////*/
@media (max-width:1027px){
    
    .Interest {
        grid-template-columns: 1fr;
    }
    .web{
        margin-bottom:25px
    }
    .app{
        margin-bottom:25px
    }
    .other{
        margin-bottom:25px
    }
}


@media (max-width:769px){
    .box{
        text-align: center;
    }
    .Interest {
        grid-template-columns: 1fr;
    }
    .app{
        margin-top:20px;
        height:100%;
         /* position: static; */
     }
     .other{
        margin-top:20px;
        height:180px; 
     }
}

@media (max-width:392px){
    .Interest {
        grid-template-columns: 1fr;
    }
    .app{
         position: static;
     }
}