@import url('https://fonts.googleapis.com/css?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap');
.sidebar{
    height:100vh;
    /* margin-top: -50px; */
    width:100%;
    background-color:#f2f3f7;
    overflow-y: scroll;
     text-align: center;
}
.sidebar > h1 {
    padding-top: 20px;
    text-align: center;
}

.h1_links{
  text-decoration: none;
  font-family: 'Aclonica', sans-serif;
  color:#000;
  font-size:32px;
}
.sidebar > p {
    margin-top: 30px;
    text-align: center;
}

.sidebar-nav{
    margin-left: -30px;
    margin-top: 30px;
    text-align: center;
}
.sidebar-nav-items{
    margin-bottom: 15px;
}
.sidebar>img{
  width: 100px;
  height: 100px;
  margin-top: 40px;
}
.links{
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Comfortaa', cursive;
  color:#000;
  font-size: larger;
  line-height: 1.4rem;
}
.links-small{
    text-decoration: none;
    /* font-family: 'Quicksand', sans-serif; */
    font-family: 'Comfortaa', cursive;
    color:#2fa499;
    font-size: smaller;
    line-height: 1.4rem;
}
.sidebar-nav-icons{
    margin-bottom: 15px;
}

.flip-card-back{
  margin-top:50px;
}
.fa-lg{
  text-decoration: none;
  color:#2fa499;
}

.links:hover{
 /* color:rgb(121, 189, 252); */
 color:rgb(121, 189, 252);
 text-decoration: underline;
}
.links-small:hover {
    color: rgb(41, 152, 255);
    text-decoration: underline;
}
.fas:hover{
  color:rgb(41, 152, 255);
  text-decoration: underline;
 }
 ul{
   list-style: none;
 }
  .h1_links:hover{
  color: #000;
 }
 /*//////////////media ////////////////////*/
 @media (max-width:980px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:29px;
  }
 }


@media (max-width:768px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:28px;
  }
  .links{
display:none;
  }
  .fas{
    transform: scale(1.3);
    margin:5px 0;
  }
 .gmail{
   font-size:16px;  
   font-weight: normal;
 }
}
 
@media (max-width:370px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:24px;
  }
  .links{
    font-size: 16px;
  }
  .fas{
    transform: scale(1.1);
    margin:5px 0;
  }

}